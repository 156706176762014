
import _ from 'underscore';

export class Breadcrumb {

    static create(text, to, active) {
        return new Breadcrumb(text, to, active);
    }

    constructor(text, to, active) {
        this.text = text;
        this.to = to;
        this.active = true === active;
    }


    equals(other) {
        return _.isEqual(this, other);
    }
}

export default Breadcrumb;
